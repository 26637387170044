.form-control.input_error, .input_error + .select2 .select2-selection
{
    border: 2px solid rgba(red, 0.5);

    &::placeholder
    {
        color: rgba(red, 0.5);
    }

    &:focus
    {
        border-color: rgba(red, 0.5);
    }
}

label.input_error
{
    color: red;
}

.select2-container
{
    .select2-search__field
    {
        outline: none !important;
    }

    .select2-selection
    {
        outline: none !important;
    }
}

img
{
    max-width: 100%;
}

// Checkbox & Radio - Fix for new lines
.css-checkbox, .css-radio
{
    &.css-checkbox-multiline, &.css-radio-multiline
    {
        .css-control-indicator
        {
            position: absolute;
            left: 0;
            top: 0.5em;
            margin: auto;
        }

        .checkbox-label
        {
            display: block;
        }
    }

    &.css-checkbox-multiline
    {
        padding-left: 28px;
    }

    &.css-radio-multiline
    {
        padding-left: 25px;
    }
}
